@font-face {
  font-family: 'stack-interface';
  src: url('/static/fonts/stack-interface.eot?33839631');
  src: url('/static/fonts/stack-interface.eot?33839631#iefix') format('embedded-opentype'),
       url('/static/fonts/stack-interface.woff2?33839631') format('woff2'),
       url('/static/fonts/stack-interface.woff?33839631') format('woff'),
       url('/static/fonts/stack-interface.ttf?33839631') format('truetype'),
       url('/static/fonts/stack-interface.svg?33839631#stack-interface') format('svg');
  font-weight: normal;
  font-style: normal;
}
 
 [class^="stack-"]:before, [class*=" stack-"]:before {
  font-family: "stack-interface";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.stack-down-open-big:before { content: '\e800'; } /* 'î €' */
.stack-left-open-big:before { content: '\e801'; } /* 'î ' */
.stack-right-open-big:before { content: '\e802'; } /* 'î ‚' */
.stack-up-open-big:before { content: '\e803'; } /* 'î ƒ' */
.stack-basket:before { content: '\e804'; } /* 'î „' */
.stack-search:before { content: '\e805'; } /* 'î …' */
.stack-down-dir:before { content: '\e806'; } /* 'î †' */
.stack-left-dir:before { content: '\e807'; } /* 'î ‡' */
.stack-right-dir:before { content: '\e808'; } /* 'î ˆ' */
.stack-up-dir:before { content: '\e809'; } /* 'î ‰' */
.stack-down-open:before { content: '\e80a'; } /* 'î Š' */
.stack-left-open:before { content: '\e80b'; } /* 'î ‹' */
.stack-right-open:before { content: '\e80c'; } /* 'î Œ' */
.stack-up-open:before { content: '\e80d'; } /* 'î ' */
.stack-menu:before { content: '\e80e'; } /* 'î Ž' */
.stack-users:before { content: '\e80f'; } /* 'î ' */
.stack-publish:before { content: '\e810'; } /* 'î ' */
.stack-trash:before { content: '\e811'; } /* 'î ‘' */
.stack-bell:before { content: '\e812'; } /* 'î ’' */
.stack-cog:before { content: '\e813'; } /* 'î “' */
.stack-plus-circled:before { content: '\e814'; } /* 'î ”' */
.stack-dot-3:before { content: '\e815'; } /* 'î •' */